
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormSelect, { CFormSelectOption } from "@/components/form/Select.vue";
import CFormInputText from "@/components/form/InputText.vue";
import PProgressBar from "primevue/progressbar";

import { UnitForm } from "@/store/unit/types";
import { isEditFormByRouteName } from "@/libs/utils";

const VUnitForm = defineComponent({
  name: "VUnitForm",
  components: { CModal, CFormInputText, PProgressBar, CFormSelect },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);
    const isEditForm = isEditFormByRouteName(route.name);

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} parceiro | obmed` });

    const unit = computed(() => store.state.unit.current);
    const currentState = ref<any>(null);
    const stateOptions = computed<CFormSelectOption[]>(() =>
      store.state.locality.allStates.map((state) => ({ label: state.nm_estado, value: state.id }))
    );
    const cityOptions = computed<CFormSelectOption[]>(() =>
      store.state.locality.allCities.map((city) => ({ label: city.nm_cidade, value: city.id }))
    );

    const form = reactive<UnitForm>({
      nm_unidade: "",
      cd_cidade: null,
    });
    const loading = reactive({ unit: false, submit: false, city: false, state: false });

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      if (!form.cd_cidade)
        return store.commit("addToast", { summary: 'O campo "Cidade" é obrigatório', severity: "warn" });

      loading.submit = true;
      const response = isEditForm
        ? await store.dispatch("updateUnit", { _id, form })
        : await store.dispatch("createUnit", { form });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getUnits");
        router.replace({ name: "unit-list" });
      }
    }

    async function getUnit() {
      loading.unit = true;
      await Promise.allSettled([store.dispatch("getUnit", { _id }), store.dispatch("getUnits")]);
      loading.unit = false;
    }

    async function getCities() {
      loading.city = true;
      const ds_uf =
        store.state.locality.allStates.find((state) => String(state.id) === String(currentState.value))?.ds_sigla || "";

      await store.dispatch("searchCity", { ds_uf });
      loading.city = false;

      if (!store.state.locality.allCities.find((city) => city.id == form.cd_cidade))
        form.cd_cidade = store.state.locality.allCities[0]?.id || null;
    }

    async function getStates() {
      loading.state = true;
      await store.dispatch("getStates");
      loading.state = false;
    }
    if (!store.state.locality.allStates.length) getStates();

    watch(currentState, getCities);

    async function handleApplyForm() {
      await getUnit();

      form.nm_unidade = unit.value?.nm_unidade || "";
      form.cd_cidade = unit.value?.cd_cidade?.id || null;
      currentState.value = unit.value?.cd_cidade?.cd_estado || null;
    }

    if (isEditForm) handleApplyForm();

    return { form, currentState, loading, isEditForm, stateOptions, cityOptions, handleClose, onSubmit };
  },
});

export default VUnitForm;
